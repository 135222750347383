var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wameed-dashboard-page-content mb-5"},[_c('page-header',{attrs:{"title":_vm.$t('users_page.employees'),"sub-title":_vm.$t('users_page.subtitle_employees')}}),_c('div',[_c('filter-header',{attrs:{"content":_vm.content},on:{"applyFilter":_vm.applyFilter,"resetFilter":_vm.resetFilter,"orderApplicants":_vm.orderApplicants}})],1),_c('section',{staticClass:"\n      wameed-dashboard-page-content_body\n      d-flex\n      flex-column\n      justify-content-between\n    "},[_c('div',{staticClass:"d-flex flex-wrap flex-1 px-2"},[_c('b-col',{staticClass:"px-0",attrs:{"lg":"12","md":"12"}},[_c('div',{staticClass:"w-table d-flex flex-column"},[(_vm.getAllEmployees)?_c('w-tables',{attrs:{"per-page":_vm.filterData.per_page,"items":_vm.getAllEmployees,"fields":_vm.fields,"status":_vm.status,"custimized-items":[
              { name: 'employee_type' },
              { name: 'name' },
              { name: 'country' },
              { name: 'phone' },
              { name: 'created_at' },
              { name: 'action' } ]},scopedSlots:_vm._u([{key:"name",fn:function(ref){
            var data = ref.data;
return [_c('div',{staticClass:"d-flex justify-center align-items-center"},[_c('b-avatar',{attrs:{"variant":"","size":"50"}},[(data.item.photo)?_c('img',{attrs:{"src":data.item.photo.full_path + data.item.photo.file_name}}):_c('default-user-image-icon')],1),_c('span',{staticClass:"text-font-main mx-3"},[_vm._v(_vm._s(data.item.name))])],1)]}},{key:"phone",fn:function(ref){
            var data = ref.data;
return [_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"text-font-main mx-3",attrs:{"dir":"ltr"}},[_vm._v(" "+_vm._s(data.item.phone))])])]}},{key:"created_at",fn:function(ref){
            var data = ref.data;
return [_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"text-font-main mx-3"},[_vm._v(" "+_vm._s(_vm.formatedDate(data.item.created_at)))])])]}},{key:"employee_type",fn:function(ref){
            var data = ref.data;
return [_c('div',{staticClass:"d-flex"},[(data.item.employee_type == 1)?_c('span',{staticClass:"text-font-main mx-3"},[_vm._v(" "+_vm._s(_vm.$t('users_data.freelance_recruitment_expert')))]):_vm._e(),(data.item.employee_type == 2)?_c('span',{staticClass:"text-font-main mx-3"},[_vm._v(" "+_vm._s(_vm.$t('users_data.agency_specialized')))]):_vm._e()])]}},{key:"country",fn:function(ref){
            var data = ref.data;
return [_c('div',{staticClass:"d-flex"},[(data.item.country)?_c('span',{staticClass:"text-font-main mx-3"},[_vm._v(" "+_vm._s(_vm.jsonParseCountry(data.item.country)))]):_vm._e()])]}},{key:"action",fn:function(ref){
            var data = ref.data;
return [_c('b-dropdown',{attrs:{"variant":"background","toggle-class":"text-decoration-none rounded-10 px-2 py-2 ","menu-class":"rounded-14","no-caret":"","dropleft":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('vertical-dots-icon',{staticClass:"mx-3 my-1"})]},proxy:true}],null,true)},[_c('b-dropdown-item',{attrs:{"link-class":"py-0min-height: 52px;"},on:{"click":function($event){return _vm.userDetails(data.item.id)}}},[_c('span',{staticClass:"text-regular-14 text-font-secondary"},[_c('eyeon-icon',{staticClass:"mx-2"}),_vm._v(" "+_vm._s(_vm.$t('common.show_details'))+" ")],1)])],1)]}}],null,false,1988315068)}):_vm._e(),(_vm.getAllEmployees && _vm.getAllEmployees.length < 1)?_c('wameed-no-data',{attrs:{"icon":"nodata-icon","title":_vm.$t('offers_page.no_data'),"sub-title":_vm.$t('offers_page.no_data_text')}}):_vm._e()],1)]),(_vm.getAllEmployees && _vm.getAllEmployees.length > 0)?_c('b-col',{staticClass:"px-0",attrs:{"lg":"12","md":"12"}},[_c('wameed-pagination',{attrs:{"page":_vm.filterData.page,"total-items":_vm.getTotalEmployees.totalItems,"per_page":_vm.filterData.per_page},on:{"changePage":_vm.changePage,"changeCurrentPage":_vm.changeCurrentPage},model:{value:(_vm.filterData.page),callback:function ($$v) {_vm.$set(_vm.filterData, "page", $$v)},expression:"filterData.page"}})],1):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }